import React from 'react';
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import SwiperCore, {  Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as styles from '../styles/pages/index.module.css'

SwiperCore.use([Autoplay, EffectFade]);

export default({data}) => {
    return (
        <Layout>
            <h1 className="visually-hidden">Angelina Garipova</h1>
            <div className={styles.layoutWrapper}>
            <Swiper
                id='on-main-page'
                className={styles.swiper}
                slidesPerView={1}
                autoplay
                delay = {3000}
                reverseDirection = {false}
                loop
                fadeEffect
            >
                {
                    data.allContentfulSection.nodes.map(section => {
                        return (
                            <>
                                {
                                    section.projects.map((project, index) => {
                                        if (project.onManePageImage) {
                                            return (
                                            <SwiperSlide
                                                key={`${section.title}-project-${index}--${project.title}`}
                                                className={styles.slide}
                                            >
                                                <Link
                                                    to={`/${section.slug}/${project.slug}`}
                                                    className={styles.link}
                                                >
                                                    <Img 
                                                        className={styles.image}
                                                        fluid={project.onManePageImage.fluid}
                                                        alt={project.onManePageImage.title}
                                                    />
                                                </Link>
                                            </SwiperSlide>

                                        )
                                        }

                                    })
                                }
                            </>
                        )

                    })
                }
            </Swiper>
            </div>
        </Layout>
    )
}

export const indexQuery = graphql`
    query indexQuery {
        allContentfulSection {
        nodes {
        title
        slug
        projects {
            title
            slug
            onManePageImage {
            title
            fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_tracedSVG
            }
            }
        }
        }
    }
    }
`